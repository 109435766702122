nav {
    background: #111111;
    font-family: "Chakra Petch";
    font-size: 25px;
}

.navLogo {
    height: 5vh;
    width: 10vw;
}

/* test */
.navText {
    color: white;
}

.navText:hover {
    color: rgb(115, 115, 115);
}

.navText:focus {
    color: rgba(211, 71, 252, 1);

}

.cartIcon {
    height: 30px;
    width: 30px;
    background-color: aliceblue;
    border-radius: 100px;
}

.visible {
    display: block;
}

.notVisible {
    display: none;
}

.navLoginBtn {
    padding: 10px;
    border-radius: 5px;
}

.navLoginBtn:hover {
    background-color: rgb(179, 180, 182);
}

.hidden {
    visibility: hidden;
}

.logoutBtn
{
    border: none;
    border-radius: 5px;
    background-color: red;
    color: white;
}

@media only screen and (max-width: 600px) {
    .navLogo {
        height: 10vh;
        width: 30vw;
    }

    .navbar-toggler:focus,
    .navbar-toggler:active,
    .navbar-toggler-icon:focus {
        outline: none!important;
        box-shadow: none!important;
        box-shadow: none!important;
    }

    .navIcons
    {
        flex-direction: column;
        gap: 2vh;
    }

    .mobileNav
    {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 2vh;
    }

    .navIcon
    {
        margin-right: 0px!important;
    }

    .profileImage {
        height: 50px;
        width: 50px;
    }
    
    .cartIcon {
        height: 50px;
        width: 50px;
    }
}