html,body,#root{
  height: 100%;
  background-color: black;
}

body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
	background-color: #000000;
}

body::-webkit-scrollbar
{
	width: 6px;
	background-color: #000000;
}

body::-webkit-scrollbar-thumb
{
	background-color: #ffffff;
}