.basicFlexBoxCol{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-content: center;
}
.basicFlexBoxRow{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    align-content: center;
    gap: 10px;
}

.basicFlexBoxRow > .box{
    background-color: white;
    color: black;
}
.basicFlexBoxRow > .box :hover{
    background-color: rgb(79, 77, 77);
}
.roundBorders{
    border:2px solid black;
    border-radius:5px;
    padding: 1vw;
}

.linkBehavior:hover{
    color: blue;
    cursor: pointer;
}

.box{
    padding:1vw;
    padding-left: 4vw;
    padding-right: 4vw;
    border: 2px solid black;
    cursor: pointer;
}

.mainContainer{
    background: linear-gradient(112.1deg, rgb(32, 38, 57) 11.4%, rgb(63, 76, 119) 70.2%);
    color: white;
    font-family: "chakra petch";
    font-size: 20px;
    display: flex;
    flex-direction: column;
}