
.Contact_body{
    background-color: rgb(0, 0, 0);

}

.col{
    margin-left: 50px;
    margin-right: 50px;
}

.float-left, .float-right{
    padding-left: 20px;
    padding-right: 20px;

}
