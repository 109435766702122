@import url("https://fonts.googleapis.com/css2?family=Chakra+Petch&display=swap");

.basicFlexCol {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.basicFlexRow {
  display: flex;
  justify-content: center;
  flex-direction: Row;
  align-items: center;
}
.bridgeElements {
  /* border:2px solid black; */
  padding: 2vw;
}
.bridgeElements:hover {
  cursor: pointer;
}

.puzzle__container {
  padding-top:2.5rem;
  height: 100vh;
  background: linear-gradient(
    112.1deg,
    rgb(32, 38, 57) 11.4%,
    rgb(63, 76, 119) 70.2%
  );
  overflow-y: hidden;
  color: #fff;
}

.puzzle__flex{
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10rem;
}

.puzzle__brigeElements {
  border: 2px solid black;
  color: #434656;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 2.5rem;
  width: 2.5rem;
  background-color: #e8efff;
  border-radius: 50%;
  margin-right: 0.4rem;
}
.puzzle__brigeElements:hover {
  opacity: 0.8;
}

.puzzle__boilerContainer {
  padding: 0 1.3rem;
}

.puzzle__currentQuestion {
  font-size: 24px;
  color: #fff;
  font-family: "Chakra Petch", sans-serif;
  margin-top: 3.5rem;
  /* text-align: center; */
}

.puzzle__card {
  color: #434656;
  background-color: #e8efff; 
  border-radius: 6px;

  padding: 1em;
  border: 1px solid black;
  margin: 1rem;
  cursor: pointer;
  width: 15vw;
  height: 25vh;
  overflow: scroll;
  font-size: 1vw;

  /* box-shadow: ; */
}




.button-6 {
  color: #434656;
  background-color: #e8efff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  cursor: pointer;
  border-radius: 10px;
  padding: 0.5em 0.7em;
  text-decoration: none;
  transition: all 250ms;
  margin-top: 1.2rem;
  margin-right: 1rem;
}

.button-6:hover,
.button-6:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}


.button-6:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}
