section{
    display:grid;
    grid-template-columns: 51% 49%;
}

.tex{
    font-family: 'Archivo Narrow';
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    margin-bottom: 100px;
/* identical to box height */

    
    color: rgba(255, 255, 255, 0.79);
}

.txt-f{
    border: none;
    background:transparent;
    border-bottom: 2px solid silver;
    outline: none;
    width: 538px;
    color: #FFFFFF;
}

.lgn-btn{
    width: 162px;
    height: 52px;
    font-family: 'Archivo Narrow';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    border: none;

    color: #FFFFFF;
    background: #6C6C6C;
    border-radius: 9px;
}

.txt{
    font-family: 'Archivo Narrow';
  display:grid;
  grid-template-columns: 51% 49%;
}

.tex{
  font-family: 'Archivo Narrow';
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  margin-bottom: 100px;
/* identical to box height */

  
  color: rgba(255, 255, 255, 0.79);
}

.txt-f{
  border: none;
  background:transparent;
  border-bottom: 2px solid silver;
  outline: none;
  width: 538px;
  color: #FFFFFF;
}

.lgn-btn{
  width: 162px;
  height: 52px;
  font-family: 'Archivo Narrow';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  border: none;

  color: #FFFFFF;
  background: #6C6C6C;
  border-radius: 9px;
}

.txt{
  font-family: 'Archivo Narrow';
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 30px;

/* identical to box height */


color: rgba(255, 255, 255, 0.79);
}

img{
    height: 100vh;
}

.imgs{
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
  height: 100vh;
}

.imgs{
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
}


.lnk{
    width: 100%;
    text-align: right;
}

.lnk a{
    text-decoration-style: none !important;
  width: 100%;
  text-align: right;
}

.lnk a{
  text-decoration-style: none !important;
}


@media screen and (max-width: 1100px){
    section{
        display: block;
        
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 450px){
    .ctn{
        display: none;
    }
  section{
      display: block;
      
      width: 100%;
      height: 100%;
  }
}

@media screen and (max-width: 450px){
  .ctn{
      display: none;
  }
}